import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Action from '../../../components/Resource/Action';
import Video from '../../../components/Video';
import SubfooterNav from '../../../components/SubfooterNav';
import '../../../styles/pages/_classroom-instruction.scss';

const ThemeTwoSamLong = () => {
  return (
    <Layout title="How to Have Necessary Conversations in the Biotech Classroom">
      <Section className="educators-action__video-hero pt-2">
        <Container fullWidth className="educators-action__video-hero-container">
          <Row className="educators-action__video-hero-intro">
            <Column size={10}>
              <h4>
                <Link to="/educators-in-action/">Introduction</Link> |{' '}
                <span>Theme Two | Sam Long</span>
              </h4>
            </Column>
          </Row>
          <Row className="educators-action__video-hero-intro">
            <Column size={10}>
              <h1>
                How to Have Necessary Conversations in the Biotech Classroom
              </h1>
            </Column>
          </Row>
          <Row className="educators-action__video-hero-videos">
            <Column size={8}>
              <Video
                className="hero-img-video__video"
                guidSrc="40b6502e-4adc-48a0-ae1c-dc14808a0ba4"
                autoPlay={true}
              />
            </Column>
            <Column size={4}>
              <div className="educators-action__video-hero-thumbs">
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb active">
                    <Image filename="img-sam-long-vid-two-poster.jpg" />
                  </span>
                  <span className="educators-action__video-hero-text">
                    <h3>
                      How to Have Necessary Conversations in the Biotech
                      Classroom
                    </h3>
                    <span>4:40min</span>
                  </span>
                </div>
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/educators-in-action/theme-two/pedro-delgado">
                      <Image filename="img-pedro-delgado-vid-one-poster.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/educators-in-action/theme-two/pedro-delgado">
                      <h3>
                        Computational Thinking in Action in the Biotech
                        Classroom
                      </h3>
                      <span>4:34min</span>
                    </Link>
                  </span>
                </div>
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/educators-in-action/theme-two/guadalupe-tapia">
                      <Image filename="img-guadalupe-tapia-vid-two-poster.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/educators-in-action/theme-two/guadalupe-tapia">
                      <h3>Equity Practices in the Biotech Classroom</h3>
                      <span>4:48min</span>
                    </Link>
                  </span>
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="educators-action__theme-content pt-0 pb-4">
        <Container
          fullWidth
          className="educators-action__theme-content-container"
        >
          <Row className="educators-action__theme-content-title pt-0 pb-1">
            <Column size={10}>
              <h2>Equip Students with the Knowledge to Overcome Inequities</h2>
            </Column>
          </Row>
          <Row className="educators-action__theme-content-body">
            <Column size={8} className="pr-3">
              <h3>Overview</h3>
              <p>
                Biotechnology provides students with vital pathways to explore
                some of the most important challenges facing the world today.
                Embrace the diverse perspectives of students while investigating
                a variety of timely biotechnology applications with Futurelab+.
                Find out how other educators are facilitating empowering biotech
                conversations and connecting students to the workforce of
                tomorrow through project-based learning, the product development
                lifecycle and more.
              </p>
              <h3>Reflect</h3>
              <p>
                Learn to apply concepts discussed in the Master Class series by
                reflecting and drawing connections to your own teaching
                practice:
              </p>
              <ul>
                <li>Honing-in on issues that are relevant to your students.</li>
                <li>
                  Harnessing the hands-on framework of project-based learning.
                </li>
                <li>
                  Connecting students to the workforce through the
                  product-development lifecycle.
                </li>
              </ul>
              <h3>Look Forward</h3>
              <p>
                Use the Educator Guide to explore next steps you can take to
                incorporate the educator practices discussed by our three Master
                Class educators in your own teaching.
              </p>
              <h3>About This Video Series</h3>
              <p>
                The Futurelab+ Master Class Series equips educators with
                strategies to engage students from diverse racial, ethnic, and
                cultural groups in lifechanging educational explorations into
                biotech.
              </p>
              <Row className="mt-3">
                <Column size={4}>
                  <div className="educators-action__profile-thumbs">
                    <Image filename="thmb-educators-profile-long@2x.png" />
                  </div>
                </Column>
                <Column size={8}>
                  <h3 className="educators-action__featured-educators-name mb-0">
                    Sam Long <span>(he/him)</span>
                  </h3>
                  <h4 className="educators-action__featured-educators-subject mt-0 mb-0.5">
                    High School Science
                  </h4>
                  <h4 className="educators-action__featured-educators-school mt-0 mb-0.5">
                    St. Vrain Valley Schools, Denver, Colorado
                  </h4>
                  <p className="educators-action__featured-educators-desc">
                    Sam is passionate about engaging every student's perspective
                    in the biotechnology conversation, while being sure to
                    ground every discussion in real-world evidence.
                  </p>
                  <h4 className="educators-action__featured-educators-videos mt-0 mb-0.5">
                    Other Featured Videos:
                  </h4>
                  <ul className="educators-action__featured-educators-links">
                    <li>
                      <Link to="/educators-in-action/theme-one/sam-long/">
                        Empowering Students to Solve Inequities in the Biotech
                        Classroom
                      </Link>
                    </li>
                  </ul>
                </Column>
              </Row>
            </Column>
            <Column size={4}>
              <div className="educators-action__educator-quote">
                <h3>
                  “We’re preparing students to make decisions and to make sense
                  of the real world in the science classroom.”
                </h3>
                <h4>
                  Sam Long <span>(he/him)</span>
                </h4>
              </div>
              <div className="educators-action__educator-guide">
                <div className="educators-action__educator-guide-heading">
                  <h4>Educator Guide</h4>
                </div>
                <div className="educators-action__educator-guide-body">
                  <h4>Educators in Action</h4>
                  <h3 className="educators-action__educator-guide-content-heading">
                    Educator Guide
                  </h3>
                  <span className="educators-action__educator-guide-duration">
                    10–15 minutes
                  </span>
                  <p>
                    This in-depth companion to the Master Class series provides
                    educators with practical real-world connections to explore
                    biotechnology in any classroom environment. Build lessons
                    using key background information, educator strategies and
                    more.
                  </p>
                  <Action
                    type="download"
                    label="Educator Guide"
                    path="/pdfs/educators-in-action/Genentech-Masterclass-Ed-Guide.pdf"
                    format=".PDF,"
                    size="722 KB"
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0 pt-0">
        <SubfooterNav
          current={{
            name: 'Theme 2',
            link: '/educators-in-action/theme-two/sam-long',
          }}
          next={{
            name: 'Theme 1',
            link: '/educators-in-action/theme-one/sam-long',
          }}
        />
      </Section>
    </Layout>
  );
};

export default ThemeTwoSamLong;
